import { apiPrivateClient } from "./apiService";
import { handleApiCall } from "./handleApiCall";

export const subCompanyService = {
  async getSubCompanies(companyId) {
    return handleApiCall(() =>
      apiPrivateClient.get(`/companies/${companyId}/sub-companies`),
    );
  },

  async createSubCompany(companyId, name, type, emailFrom, address) {
    return handleApiCall(() =>
      apiPrivateClient.post(`/companies/${companyId}/sub-companies`, {
        name: name,
        type: type,
        email_from: emailFrom,
        address: address,
      }),
    );
  },

  async getSubCompany(companyId, subCompanyId) {
    return handleApiCall(() =>
      apiPrivateClient.get(
        `/companies/${companyId}/sub-companies/${subCompanyId}`,
      ),
    );
  },
  async updateSubCompany(companyId, subCompanyId, name, emailFrom) {
    return handleApiCall(() =>
      apiPrivateClient.put(
        `/companies/${companyId}/sub-companies/${subCompanyId}`,
        {
          name: name,
          email_from: emailFrom,
        },
      ),
    );
  },
};
