import Layout from "./Layout";
import { useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { emailService } from "../api/emailService";
import { toast } from "sonner";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { payrollService } from "../api/payrollService";
import { subCompanyService } from "../api/subCompanyService";
import { DataGrid } from "@mui/x-data-grid";

const EmailDetails = () => {
  const { emailId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [emailData, setEmailData] = useState(null);
  const [payrollColumns, setPayrollColumns] = useState([]);

  const [payrolls, setPayrolls] = useState([]);

  const loadEmail = useCallback(async () => {
    setIsLoading(true);
    try {
      const email = await emailService.getEmail(emailId);
      setEmailData(email);
      const subCompanyData = await subCompanyService.getSubCompany(
        email.company_id,
        email.sub_company_id,
      );
      console.log(subCompanyData);
      setPayrollColumns(getPayrollColumns(subCompanyData));
    } catch (error) {
      toast.error(error.Message || "Error getting email");
    }
    setIsLoading(false);
  }, [emailId]);

  const loadPayrolls = useCallback(async () => {
    setIsLoading(true);
    try {
      const payrolls = await payrollService.getPayrollsByEmail(emailId);
      setPayrolls(payrolls);
    } catch (error) {
      toast.error(error.Message || "Error getting payrolls");
    }
    setIsLoading(false);
  }, [emailId]);

  useEffect(() => {
    loadEmail();
    loadPayrolls();
  }, [loadEmail, loadPayrolls]);

  const handleOnDownloadAttachment = useCallback(async (url) => {
    setIsLoading(true);
    try {
      window.open(url);
    } catch (error) {
      toast.error(error.Message || "Error downloading attachment");
    }
    setIsLoading(false);
  }, []);

  const getPayrollColumns = (subCompanyData) => {
    let columns = [
      {
        field: "createdAt",
        headerName: "Created At",
        flex: 0.5,
        headerClassName: "column-header",
        valueFormatter: (params) =>
          moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
      },
      {
        field: "techName",
        flex: 1,
        headerName: "Tech Name",
        headerClassName: "column-header",
        cellClassName: "titled",
      },
      {
        field: "hours",
        flex: 0.3,
        headerName: "Hours",
        headerClassName: "column-header",
      },
      {
        field: "startDate",
        flex: 0.5,
        headerName: "Start Date",
        headerClassName: "column-header",
      },
      {
        field: "endDate",
        flex: 0.5,
        headerName: "End Date",
        headerClassName: "column-header",
      },

      {
        field: "status",
        flex: 0.5,
        headerName: "Status",
        headerClassName: "column-header",
      },
      {
        field: "syncDate",
        flex: 0.5,
        headerName: "Sync Date",
        headerClassName: "column-header",
      },
    ];

    if (subCompanyData.type === "supplier") {
      return [
        ...columns.slice(0, 3),
        {
          field: "value",
          flex: 0.3,
          headerName: "Rate",
          headerClassName: "column-header",
          valueFormatter: (params) => {
            if (params.value) {
              return `$ ${params.value.toLocaleString()}`;
            }
            return "";
          },
        },
        {
          field: "expeditionDate",
          flex: 0.5,
          headerName: "Expedition Date",
          headerClassName: "column-header",
        },
        {
          field: "invoiceNumber",
          flex: 0.5,
          headerName: "Invoice Number",
          headerClassName: "column-header",
        },
        ...columns.slice(3),
      ];
    } else {
      return [
        ...columns.slice(0, 3),
        {
          field: "value",
          flex: 0.3,
          headerName: "Total",
          headerClassName: "column-header",
          valueFormatter: (params) => {
            if (params.value) {
              return `$ ${params.value.toLocaleString()}`;
            }
            return "";
          },
        },
        ...columns.slice(3),
      ];
    }
  };

  const getPayrollsData = () => {
    return payrolls.map((payroll) => {
      return {
        id: payroll.id,
        createdAt: new Date(payroll.created_at + "Z"),
        techName: payroll.tech_name,
        hours: payroll.hours,
        startDate: payroll.pay_period_start_date,
        endDate: payroll.pay_period_end_date,
        status: payroll.status,
        syncDate: payroll.sync_date,
        value: payroll.value,
        expeditionDate: payroll.expedition_date,
        invoiceNumber: payroll.invoice_number,
      };
    });
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3">Email Details</Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            navigate(-1, { replace: true });
          }}
        >
          Back
        </Button>
      </Box>
      <Divider sx={{ mb: 2, mt: 2 }} />
      {isLoading && <LinearProgress />}
      {!isLoading && emailData && (
        <Box>
          <TableContainer>
            <Table aria-label="simple table">
              <colgroup>
                <col width="300px" />
              </colgroup>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Received At</TableCell>
                  <TableCell>
                    {moment(emailData.receive_datetime).format(
                      "MMMM Do YYYY, h:mm:ss a",
                    )}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Num. of Payrolls</TableCell>
                  <TableCell>{emailData.num_of_payroll_entities}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Num. of Valid Attachments</TableCell>
                  <TableCell>{emailData.num_of_valid_attachments}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Sub Company Name</TableCell>
                  <TableCell>{emailData.sub_company_name}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Sub Company Type</TableCell>
                  <TableCell>{emailData.sub_company_type}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Sender</TableCell>
                  <TableCell>{emailData.sender}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Subject</TableCell>
                  <TableCell>{emailData.subject}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="body-content"
              id="body-header"
            >
              Body
            </AccordionSummary>
            <AccordionDetails>
              <div dangerouslySetInnerHTML={{ __html: emailData.body }} />
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="body-content"
              id="body-header"
            >
              Attachments
            </AccordionSummary>
            <AccordionDetails>
              {emailData.attachments.map((attachment, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    border: "2px solid",
                    borderColor: "grey.300",
                    p: 2,
                  }}
                >
                  <Typography variant="h6">{attachment.name}</Typography>
                  <Typography variant="body1">
                    <strong>Content-Type:</strong> {attachment.content_type}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      handleOnDownloadAttachment(attachment.url);
                    }}
                    sx={{
                      maxWidth: "200px",
                    }}
                  >
                    Download
                  </Button>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h4">Payrolls</Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <DataGrid
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-row:hover": {},
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                  {
                    outline: "none !important",
                  },
              }}
              rows={getPayrollsData()}
              getRowHeight={() => "auto"}
              columns={payrollColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableRowSelectionOnClick
            />
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default EmailDetails;
