import { apiPrivateClient } from "./apiService";
import { handleApiCall } from "./handleApiCall";

export const invoiceService = {
  async getInvoices(companyId) {
    return handleApiCall(() =>
      apiPrivateClient.get(`/companies/${companyId}/invoices`),
    );
  },

  async getInvoice(companyId, invoiceId) {
    return handleApiCall(() =>
      apiPrivateClient.get(`/companies/${companyId}/invoices/${invoiceId}`),
    );
  },

  async markInvoiceAsSent(companyId, invoiceId) {
    return handleApiCall(() =>
      apiPrivateClient.post(
        `/companies/${companyId}/invoices/${invoiceId}/mark-as-sent`,
      ),
    );
  },

  async markInvoiceAsPending(companyId, invoiceId) {
    return handleApiCall(() =>
      apiPrivateClient.post(
        `/companies/${companyId}/invoices/${invoiceId}/mark-as-pending`,
      ),
    );
  },

  async markInvoiceAsNoCreated(companyId, invoiceId) {
    return handleApiCall(() =>
      apiPrivateClient.post(
        `/companies/${companyId}/invoices/${invoiceId}/mark-as-no-created`,
      ),
    );
  },
};
