import "./App.css";
import Login from "./components/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { Toaster } from "sonner";
import { CssBaseline, Stack, ThemeProvider } from "@mui/material";
import { theme } from "./Theme";
import RequireAuth from "./components/RequireAuth";
import { AuthProvider } from "./context/AuthProvider";
import Home from "./components/Home";
import Logout from "./components/Logout";
import CompanyDetails from "./components/CompanyDetails";
import EmailDetails from "./components/EmailDetails";
import InvoiceDetails from "./components/InvoiceDetails";

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Stack className="background">
          <Toaster
            position="top-center"
            visibleToasts={3}
            closeButton
            richColors
          />
          <BrowserRouter>
            <Routes>
              <Route element={<RequireAuth />}>
                <Route path="/" element={<Home />} />
              </Route>
              <Route
                path="/companies/:companyId"
                element={<CompanyDetails />}
              />
              <Route path="/emails/:emailId" element={<EmailDetails />} />
              <Route
                path="/companies/:companyId/invoices/:invoiceId"
                element={<InvoiceDetails />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/404" element={<ErrorPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </BrowserRouter>
        </Stack>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
