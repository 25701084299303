export const load = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  return auth ? auth : null;
};

export const clean = () => {
  localStorage.removeItem("auth");
};

export const save = (auth) => {
  localStorage.setItem("auth", JSON.stringify(auth));
};

export const convertFromAPIResponse = (response) => {
  return {
    email: response.email,
    firstName: response.first_name,
    lastName: response.last_name,
    token: response.token,
    canTest: response.can_test,
  };
};
