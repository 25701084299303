import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Divider,
  LinearProgress,
} from "@mui/material";
import { toast } from "sonner";
import { validateEmail } from "../helpers/stringHelper";
import { subCompanyService } from "../api/subCompanyService";

const AddSubCompany = ({ companyId, isOpen, handleOnSave, handleOnCancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [subCompanyName, setSubCompanyName] = useState("");
  const [isValidSubCompanyName, setIsValidSubCompanyName] = useState(true);

  const [type, setType] = useState("");
  const [isValidType, setIsValidType] = useState(true);

  const [emailFrom, setEmailFrom] = useState("");
  const [isValidEmailFrom, setIsValidEmailFrom] = useState(true);

  const [line1, setLine1] = useState("");
  const [isValidLine1, setIsValidLine1] = useState(true);

  const [line2, setLine2] = useState("");
  const [isValidLine2, setIsValidLine2] = useState(true);

  const [city, setCity] = useState("");
  const [isValidCity, setIsValidCity] = useState(true);

  const [state, setState] = useState("");
  const [isValidState, setIsValidState] = useState(true);

  const [zipCode, setZipCode] = useState("");
  const [isValidZipCode, setIsValidZipCode] = useState(true);

  const [country, setCountry] = useState("");
  const [isValidCountry, setIsValidCountry] = useState(true);

  const handleOnChangeSubCompanyName = useCallback((e) => {
    setSubCompanyName(e.target.value);
    setIsValidSubCompanyName(true);
  }, []);

  const handleOnChangeType = useCallback((e) => {
    setType(e.target.value);
    setIsValidType(true);
  }, []);

  const handleOnChangeEmailFrom = useCallback((e) => {
    setEmailFrom(e.target.value);
    setIsValidEmailFrom(true);
  }, []);

  const handleOnChangeLine1 = useCallback((e) => {
    setLine1(e.target.value);
    setIsValidLine1(true);
  }, []);

  const handleOnChangeLine2 = useCallback((e) => {
    setLine2(e.target.value);
    setIsValidLine2(true);
  }, []);

  const handleOnChangeCity = useCallback((e) => {
    setCity(e.target.value);
    setIsValidCity(true);
  }, []);

  const handleOnChangeState = useCallback((e) => {
    setState(e.target.value);
    setIsValidState(true);
  }, []);

  const handleOnChangeZipCode = useCallback((e) => {
    setZipCode(e.target.value);
    setIsValidZipCode(true);
  }, []);

  const handleOnChangeCountry = useCallback((e) => {
    setCountry(e.target.value);
    setIsValidCountry(true);
  }, []);

  const isValidData = useCallback(() => {
    let isValid = true;
    if (subCompanyName.trim().length > 0) {
      setIsValidSubCompanyName(true);
    } else {
      setIsValidSubCompanyName(false);
      isValid = false;
    }

    if (type.trim().length > 0) {
      setIsValidType(true);
    } else {
      setIsValidType(false);
      isValid = false;
    }

    if (emailFrom.length > 0 && validateEmail(emailFrom)) {
      setIsValidEmailFrom(true);
    } else {
      setIsValidEmailFrom(false);
      isValid = false;
    }

    if (line1.trim().length > 0) {
      setIsValidLine1(true);
    } else {
      setIsValidLine1(false);
      isValid = false;
    }

    if (line2.trim().length > 0) {
      setIsValidLine2(true);
    } else {
      setIsValidLine2(false);
      isValid = false;
    }

    if (city.trim().length > 0) {
      setIsValidCity(true);
    } else {
      setIsValidCity(false);
      isValid = false;
    }

    if (state.trim().length > 0) {
      setIsValidState(true);
    } else {
      setIsValidState(false);
      isValid = false;
    }

    if (zipCode.trim().length > 0) {
      setIsValidZipCode(true);
    } else {
      setIsValidZipCode(false);
      isValid = false;
    }

    if (country.length > 0) {
      setIsValidCountry(true);
    } else {
      setIsValidCountry(false);
      isValid = false;
    }

    return isValid;
  }, [
    subCompanyName,
    type,
    emailFrom,
    line1,
    line2,
    city,
    state,
    zipCode,
    country,
  ]);

  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();
      if (isValidData()) {
        setIsLoading(true);
        const address = {
          line1: line1,
          line2: line2,
          city: city,
          state: state,
          postal_code: zipCode,
          country: country,
        };
        try {
          await subCompanyService.createSubCompany(
            companyId,
            subCompanyName,
            type,
            emailFrom,
            address,
          );
          toast.success("Sub Company created successfully");
          handleOnSave({
            subCompanyName,
            type,
            emailFrom,
            address,
          });
        } catch (error) {
          toast.error(error.Message || "Error creating Sub Company");
        }
        setIsLoading(false);
      }
    },
    [
      companyId,
      subCompanyName,
      type,
      emailFrom,
      line1,
      line2,
      city,
      state,
      zipCode,
      country,
      isValidData,
      handleOnSave,
    ],
  );

  const handleClose = () => {
    handleOnCancel();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Create a new Sub Company</DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>

        <FormControl fullWidth>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 2,
              gap: 2,
            }}
          >
            {isLoading && <LinearProgress />}
            <TextField
              fullWidth
              id="sub-company-name"
              label="Sub Company Name"
              name="sub-company-name"
              type="text"
              required
              value={subCompanyName}
              error={!isValidSubCompanyName}
              helperText={
                !isValidSubCompanyName ? "Enter a Sub Company name" : ""
              }
              onChange={handleOnChangeSubCompanyName}
              disabled={isLoading}
            />
            <FormControl fullWidth>
              <InputLabel id="sub-company-type" error={!isValidType}>
                Sub Company Type
              </InputLabel>
              <Select
                labelId="sub-company-type"
                id="sub-company-type"
                label="Sub Company Type"
                name="sub-company-type"
                value={type}
                error={!isValidType}
                onChange={handleOnChangeType}
                disabled={isLoading}
                required
              >
                <MenuItem value={"customer"}>Customer</MenuItem>
                <MenuItem value={"supplier"}>Supplier</MenuItem>
              </Select>
              {!isValidType && (
                <FormHelperText error={!isValidType}>
                  Select a Sub Company Type
                </FormHelperText>
              )}
            </FormControl>
            <TextField
              fullWidth
              id="email-from"
              label="Email From"
              name="email-from"
              type="text"
              required
              value={emailFrom}
              error={!isValidEmailFrom}
              helperText={
                !isValidEmailFrom ? "Enter a valid Email Address" : ""
              }
              onChange={handleOnChangeEmailFrom}
              disabled={isLoading}
            />
            <Divider />
            <TextField
              fullWidth
              id="line1"
              label="Line 1"
              name="line1"
              type="text"
              required
              value={line1}
              error={!isValidLine1}
              helperText={!isValidLine1 ? "Enter a valid Address" : ""}
              onChange={handleOnChangeLine1}
              disabled={isLoading}
            />
            <TextField
              fullWidth
              id="line2"
              label="Line 2"
              name="line2"
              type="text"
              required
              value={line2}
              error={!isValidLine2}
              helperText={!isValidLine2 ? "Enter a valid Address" : ""}
              onChange={handleOnChangeLine2}
              disabled={isLoading}
            />
            <TextField
              fullWidth
              id="city"
              label="City"
              name="city"
              type="text"
              required
              value={city}
              error={!isValidCity}
              helperText={!isValidCity ? "Enter a valid City" : ""}
              onChange={handleOnChangeCity}
              disabled={isLoading}
            />
            <TextField
              fullWidth
              id="state"
              label="State"
              name="state"
              type="text"
              required
              value={state}
              error={!isValidState}
              helperText={!isValidState ? "Enter a valid State" : ""}
              onChange={handleOnChangeState}
              disabled={isLoading}
            />
            <TextField
              fullWidth
              id="zip-code"
              label="Zip Code"
              name="zip-code"
              type="text"
              required
              value={zipCode}
              error={!isValidZipCode}
              helperText={!isValidZipCode ? "Enter a valid Zip Code" : ""}
              onChange={handleOnChangeZipCode}
              disabled={isLoading}
            />
            <TextField
              fullWidth
              id="Country"
              label="Country"
              name="Country"
              type="text"
              required
              value={country}
              error={!isValidCountry}
              helperText={!isValidCountry ? "Enter a valid Country" : ""}
              onChange={handleOnChangeCountry}
              disabled={isLoading}
            />
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={isLoading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSubCompany;
