import { apiClient } from './apiService';
import { handleApiCall } from './handleApiCall';

export const userService = {
    async auth(email, password) {
        return handleApiCall(() =>
            apiClient.post('/admin-users/me', {}, {
                auth: {
                    username: email,
                    password: password
                }
            }),
        );
    },
};
