import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "./Layout";
import {
  Box,
  Button,
  Chip,
  Divider,
  LinearProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  IconButton,
} from "@mui/material";
import { companyService } from "../api/companyService";
import { toast } from "sonner";
import { subCompanyService } from "../api/subCompanyService";
import moment from "moment/moment";
import AddSubCompany from "./AddSubCompany";
import { DataGrid } from "@mui/x-data-grid";
import { productService } from "../api/productService";
import AddProduct from "./AddProduct";
import { emailService } from "../api/emailService";
import DownloadIcon from "@mui/icons-material/Download";
import LaunchIcon from "@mui/icons-material/Launch";
import ErrorIcon from "@mui/icons-material/Error";
import ModalInfo from "./ModalInfo";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EditSubCompany from "./EditSubCompany";
import EditProduct from "./EditProduct";
import { invoiceService } from "../api/invoiceService";
import convertInvoiceStatus from "../helpers/convertInvoiceStatus";
import { getClassNameBasedOnInvoiceStatus } from "../helpers/getClassNameBasedOnInvoiceStatus";

const CompanyDetails = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();

  const [companyData, setCompanyData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [subCompanies, setSubCompanies] = useState([]);
  const [isAddSubCompanyOpen, setIsAddSubCompanyOpen] = useState(false);

  const [products, setProducts] = useState([]);
  const [isAddProductOpen, setIsAddProductOpen] = useState(false);

  const [emails, setEmails] = useState([]);
  const [isErrorEmailShown, setIsErrorEmailShown] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");

  const [subCompanyIdToEdit, setSubCompanyIdToEdit] = useState(null);
  const [isEditSubCompanyOpen, setIsEditSubCompanyOpen] = useState(false);

  const [productIdToEdit, setProductIdToEdit] = useState(null);
  const [supplierIdToProductToEdit, setSupplierIdToProductToEdit] =
    useState(null);
  const [isEditProductOpen, setIsEditProductOpen] = useState(false);

  const [invoices, setInvoices] = useState([]);

  const loadCompany = useCallback(async () => {
    setIsLoading(true);
    try {
      const company = await companyService.getCompany(companyId);
      setCompanyData(company);
    } catch (error) {
      toast.error(error.Message || "Error getting company");
    }
    setIsLoading(false);
  }, [companyId]);

  const loadSubCompanies = useCallback(async () => {
    setIsLoading(true);
    try {
      const subCompanies = await subCompanyService.getSubCompanies(companyId);
      setSubCompanies(subCompanies);
    } catch (error) {
      toast.error(error.Message || "Error getting sub companies");
    }
    setIsLoading(false);
  }, [companyId]);

  const loadProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const products = await productService.getAllProducts(companyId);
      setProducts(products);
    } catch (error) {
      toast.error(error.Message || "Error getting products");
    }
    setIsLoading(false);
  }, [companyId]);

  const loadEmails = useCallback(async () => {
    setIsLoading(true);
    try {
      const emails = await emailService.getAllEmails(companyId);
      setEmails(emails);
    } catch (error) {
      toast.error("Error getting emails");
    }
    setIsLoading(false);
  }, [companyId]);

  const loadInvoices = useCallback(async () => {
    setIsLoading(true);
    try {
      const invoices = await invoiceService.getInvoices(companyId);
      setInvoices(invoices);
    } catch (error) {
      toast.error(error.Message || "Error getting invoices");
    }
    setIsLoading(false);
  }, [companyId]);

  useEffect(() => {
    loadCompany();
    loadSubCompanies();
    loadProducts();
    loadEmails();
    loadInvoices();
  }, [loadSubCompanies, loadProducts, loadEmails, loadCompany, loadInvoices]);

  const handleOnDownloadAttachments = useCallback(async (emailId) => {
    setIsLoading(true);
    try {
      const urls = await emailService.getDownloadAttachmentsUrls(emailId);
      urls.forEach((url) => {
        window.open(url);
      });
    } catch (error) {
      toast.error(error.Message || "Error downloading attachments");
    }
    setIsLoading(false);
  }, []);

  const handleOnErrorMessage = useCallback((row) => {
    setIsErrorEmailShown(true);
    setErrorEmail(row.errorMessage);
  }, []);

  const renderSubCompanyActions = (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <IconButton
          variant="outlined"
          color="primary"
          size="small"
          aria-label="edit"
          onClick={() => {
            setSubCompanyIdToEdit(params.row.id);
            setIsEditSubCompanyOpen(true);
          }}
          title="Edit"
        >
          <ModeEditIcon />
        </IconButton>
      </Box>
    );
  };

  const subCompanyColumns = [
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.5,
      headerClassName: "column-header",
      valueFormatter: (params) =>
        moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      field: "name",
      flex: 1,
      headerName: "Name",
      headerClassName: "column-header",
    },
    {
      field: "emailFrom",
      flex: 1.0,
      headerName: "Email From",
      headerClassName: "column-header",
    },
    {
      field: "type",
      flex: 0.5,
      headerName: "Type",
      headerClassName: "column-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: renderSubCompanyActions,
      headerClassName: "column-header",
    },
  ];

  const renderProductActions = (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <IconButton
          variant="outlined"
          color="primary"
          size="small"
          aria-label="edit"
          onClick={() => {
            setProductIdToEdit(params.row.id);
            setSupplierIdToProductToEdit(params.row.subCompanyId);
            setIsEditProductOpen(true);
          }}
          disabled={params.row.subCompanyType !== "supplier"}
          title="Edit"
        >
          <ModeEditIcon />
        </IconButton>
      </Box>
    );
  };

  const productColumns = [
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.5,
      headerClassName: "column-header",
      valueFormatter: (params) =>
        moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      field: "techName",
      flex: 1,
      headerName: "Tech Name",
      headerClassName: "column-header",
      cellClassName: "titled",
    },
    {
      field: "purchasePrice",
      flex: 0.5,
      headerName: "Purchase Price",
      headerClassName: "column-header",
      valueFormatter: (params) => {
        if (params.value) {
          return `$ ${params.value.toLocaleString()}`;
        }
        return "";
      },
    },
    {
      field: "salesPrice",
      flex: 0.5,
      headerName: "Sales Price",
      headerClassName: "column-header",
      valueFormatter: (params) => {
        if (params.value) {
          return `$ ${params.value.toLocaleString()}`;
        }
        return "";
      },
    },
    {
      field: "subCompany",
      flex: 1,
      headerName: "Sub Company",
      headerClassName: "column-header",
    },
    {
      field: "subCompanyType",
      flex: 0.5,
      headerName: "Sub Company Type",
      headerClassName: "column-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: renderProductActions,
      headerClassName: "column-header",
    },
  ];

  const renderEmailActions = (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <IconButton
          variant="outlined"
          color="primary"
          size="small"
          aria-label="open"
          onClick={() => {
            navigate(`/emails/${params.row.id}`);
          }}
          title="Open"
          disabled={params.row.errorMessage}
        >
          <LaunchIcon />
        </IconButton>
        <IconButton
          variant="outlined"
          color="primary"
          size="small"
          aria-label="open"
          onClick={() => {
            handleOnDownloadAttachments(params.row.id);
          }}
          title="Download Attachments"
        >
          <DownloadIcon />
        </IconButton>
        <IconButton
          variant="outlined"
          color="primary"
          size="small"
          aria-label="open"
          onClick={() => {
            handleOnErrorMessage(params.row);
          }}
          title="Show Error"
          disabled={!params.row.errorMessage}
        >
          <ErrorIcon />
        </IconButton>
      </Box>
    );
  };

  const emailColumns = [
    {
      field: "receivedAt",
      headerName: "Received At",
      width: 300,
      headerClassName: "column-header",
      valueFormatter: (params) =>
        moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      field: "numOfPayrollEntities",
      width: 220,
      headerName: "Num. of Payrolls",
      headerClassName: "column-header",
    },
    {
      field: "numOfValidAttachments",
      width: 270,
      headerName: "Num. of Valid Attachments",
      headerClassName: "column-header",
    },
    {
      field: "sender",
      width: 450,
      headerName: "Sender",
      headerClassName: "column-header",
    },
    {
      field: "subject",
      width: 400,
      headerName: "Subject",
      headerClassName: "column-header",
    },
    {
      field: "subCompany",
      width: 300,
      headerName: "Sub Company",
      headerClassName: "column-header",
    },
    {
      field: "subCompanyType",
      width: 300,
      headerName: "Sub Company Type",
      headerClassName: "column-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 200,
      renderCell: renderEmailActions,
      headerClassName: "column-header",
    },
  ];

  const renderQboBillIds = (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        {params.row.qboBillIds.map((billId) => (
          <Chip
            key={params.row.id + "-" + billId}
            label={billId}
            variant="outlined"
            color="primary"
          />
        ))}
      </Box>
    );
  };

  const renderQboInvoiceId = (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          minHeight: "52px",
        }}
      >
        {params.row.qboId && (
          <Chip
            key={params.row.id + "-" + params.row.qboId}
            label={params.row.qboId}
            variant="outlined"
            color="primary"
          />
        )}
      </Box>
    );
  };

  const renderInvoiceActions = (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <IconButton
          variant="outlined"
          color="primary"
          size="small"
          aria-label="open"
          onClick={() => {
            navigate(`/companies/${companyId}/invoices/${params.row.id}`);
          }}
          title="Open"
          disabled={params.row.errorMessage}
        >
          <LaunchIcon />
        </IconButton>
      </Box>
    );
  };

  const invoiceColumns = [
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      headerClassName: "column-header",
      valueFormatter: (params) =>
        moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      field: "startDate",
      flex: 0.5,
      headerName: "Start Date",
      headerClassName: "column-header",
    },
    {
      field: "endDate",
      flex: 0.5,
      headerName: "End Date",
      headerClassName: "column-header",
    },
    {
      field: "qboId",
      flex: 1,
      headerName: "QBO Invoice Id",
      headerClassName: "column-header",
      renderCell: renderQboInvoiceId,
    },
    {
      field: "qboBillIds",
      flex: 1,
      headerName: "QBO Bill Ids",
      headerClassName: "column-header",
      renderCell: renderQboBillIds,
    },
    {
      field: "status",
      flex: 0.5,
      headerName: "Status",
      headerClassName: "column-header",
      cellClassName: (params) => {
        console.log(params);
        return getClassNameBasedOnInvoiceStatus(params.row.status);
      },
    },
    {
      field: "syncDate",
      flex: 0.5,
      headerName: "Sync Date",
      headerClassName: "column-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: renderInvoiceActions,
      headerClassName: "column-header",
    },
  ];

  const getSubCompaniesData = () => {
    return subCompanies.map((subCompany) => {
      return {
        id: subCompany.id,
        createdAt: new Date(subCompany.created_at + "Z"),
        name: subCompany.name,
        emailFrom: subCompany.email_from,
        type: subCompany.type,
      };
    });
  };

  const getProductsData = () => {
    return products.map((product) => {
      return {
        id: product.id,
        createdAt: new Date(product.created_at + "Z"),
        techName: product.tech_name,
        purchasePrice: product.purchase_price,
        salesPrice: product.sales_price,
        subCompany: product.sub_company_name,
        subCompanyType: product.sub_company_type,
        subCompanyId: product.sub_company_id,
      };
    });
  };

  const getEmailsData = () => {
    return emails.map((email) => {
      return {
        id: email.id,
        createdAt: new Date(email.created_at + "Z"),
        receivedAt: new Date(email.receive_datetime),
        numOfPayrollEntities: email.num_of_payroll_entities,
        numOfValidAttachments: email.num_of_valid_attachments,
        sender: email.sender,
        subject: email.subject,
        subCompany: email.sub_company_name,
        subCompanyType: email.sub_company_type,
        errorMessage: email.error_message,
      };
    });
  };

  const getInvoicesData = () => {
    return invoices.map((invoice) => {
      return {
        id: invoice.id,
        createdAt: new Date(invoice.created_at + "Z"),
        startDate: invoice.pay_period_start_date,
        endDate: invoice.pay_period_end_date,
        qboId: invoice.quickbooks_id,
        qboBillIds: invoice.quickbooks_bill_ids,
        status: convertInvoiceStatus(invoice.status),
        syncDate: invoice.sync_date,
      };
    });
  };

  const getSuppliers = () => {
    return subCompanies.filter((subCompany) => subCompany.type === "supplier");
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3">Company Details</Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            navigate("/");
          }}
        >
          Back
        </Button>
      </Box>
      <Divider sx={{ mb: 2, mt: 2 }} />
      {isLoading && <LinearProgress />}
      {!isLoading && companyData && (
        <Box>
          <TableContainer>
            <Table aria-label="simple table">
              <colgroup>
                <col width="200px" />
              </colgroup>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Name</TableCell>
                  <TableCell>{companyData.name}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Recipient Emails</TableCell>
                  <TableCell>
                    {companyData.recipient_emails.map((email) => (
                      <Chip
                        key={companyData.id + "-" + email}
                        label={email}
                        variant="outlined"
                        color="primary"
                      />
                    ))}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Is Test</TableCell>
                  <TableCell> {companyData.is_test ? "Yes" : "No"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h4">Sub Companies</Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setIsAddSubCompanyOpen(true);
              }}
            >
              Add New
            </Button>
          </Box>
          {isAddSubCompanyOpen && (
            <AddSubCompany
              isOpen={isAddSubCompanyOpen}
              companyId={companyId}
              handleOnSave={(args) => {
                setIsAddSubCompanyOpen(false);
                loadSubCompanies();
                console.log(args);
              }}
              handleOnCancel={() => {
                setIsAddSubCompanyOpen(false);
              }}
            />
          )}
          {isEditSubCompanyOpen && (
            <EditSubCompany
              isOpen={isEditSubCompanyOpen}
              companyId={companyId}
              subCompanyId={subCompanyIdToEdit}
              handleOnSave={(args) => {
                setIsEditSubCompanyOpen(false);
                loadSubCompanies();
                console.log(args);
              }}
              handleOnCancel={() => {
                setIsEditSubCompanyOpen(false);
              }}
            />
          )}
          <Box sx={{ width: "100%" }}>
            <DataGrid
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-row:hover": {},
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                  {
                    outline: "none !important",
                  },
              }}
              rows={getSubCompaniesData()}
              columns={subCompanyColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableRowSelectionOnClick
            />
          </Box>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h4">Products</Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setIsAddProductOpen(true);
              }}
            >
              Add New
            </Button>
          </Box>
          {isAddProductOpen && (
            <AddProduct
              isOpen={isAddProductOpen}
              companyId={companyId}
              suppliers={getSuppliers()}
              handleOnSave={(args) => {
                setIsAddProductOpen(false);
                loadProducts();
                console.log(args);
              }}
              handleOnCancel={() => {
                setIsAddProductOpen(false);
              }}
            />
          )}
          {isEditProductOpen && (
            <EditProduct
              isOpen={isEditProductOpen}
              companyId={companyId}
              supplierId={supplierIdToProductToEdit}
              productId={productIdToEdit}
              handleOnSave={(args) => {
                setIsEditProductOpen(false);
                loadProducts();
                console.log(args);
              }}
              handleOnCancel={() => {
                setIsEditProductOpen(false);
              }}
            />
          )}
          <Box sx={{ width: "100%" }}>
            <DataGrid
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-row:hover": {},
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                  {
                    outline: "none !important",
                  },
              }}
              rows={getProductsData()}
              getRowHeight={() => "auto"}
              columns={productColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableRowSelectionOnClick
            />
          </Box>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h4">Emails</Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <DataGrid
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-row:hover": {},
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                  {
                    outline: "none !important",
                  },
              }}
              rows={getEmailsData()}
              columns={emailColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableRowSelectionOnClick
            />
          </Box>
          {isErrorEmailShown && (
            <ModalInfo
              open={isErrorEmailShown}
              title="Email Error"
              message={errorEmail}
              handleOnClose={() => {
                setIsErrorEmailShown(false);
                setErrorEmail("");
              }}
            />
          )}
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h4">Invoices</Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <DataGrid
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-row:hover": {},
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                  {
                    outline: "none !important",
                  },
              }}
              rows={getInvoicesData()}
              getRowHeight={() => "auto"}
              columns={invoiceColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableRowSelectionOnClick
            />
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default CompanyDetails;
