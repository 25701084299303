const convertInvoiceStatus = (status) => {
  if (status === "pending") {
    return "Pending";
  } else if (status === "sent") {
    return "Sent";
  } else if (status === "no_created") {
    return "No Created";
  }
};

export default convertInvoiceStatus;
