import { apiPrivateClient } from "./apiService";
import { handleApiCall } from "./handleApiCall";

export const payrollService = {
  async getPayrollsByEmail(emailId) {
    return handleApiCall(() =>
      apiPrivateClient.get(`/emails/${emailId}/payrolls`),
    );
  },

  async getPayrollsByInvoice(companyId, invoiceId) {
    return handleApiCall(() =>
      apiPrivateClient.get(
        `/companies/${companyId}/invoices/${invoiceId}/payrolls`,
      ),
    );
  },
};
