import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  LinearProgress,
  TextField,
} from "@mui/material";
import { toast } from "sonner";
import { companyService } from "../api/companyService";
import { validateEmail } from "../helpers/stringHelper";

const EditCompany = ({ companyId, isOpen, handleOnSave, handleOnCancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [isValidCompanyName, setIsValidCompanyName] = useState(true);

  const [recipientEmails, setRecipientEmails] = useState("");
  const [isValidRecipientEmails, setIsValidRecipientEmails] = useState(true);

  const [isTest, setIsTest] = useState(false);

  useEffect(() => {
    const loadCompany = async () => {
      setIsLoading(true);
      try {
        const company = await companyService.getCompany(companyId);
        setCompanyName(company.name);
        setRecipientEmails(company.recipient_emails.join("\n"));
        setIsTest(company.is_test);
      } catch (error) {
        toast.error(error.Message || "Error getting Company");
      }
      setIsLoading(false);
    };
    loadCompany();
  }, [companyId]);

  const handleOnChangeCompanyName = useCallback((e) => {
    setCompanyName(e.target.value);
    setIsValidCompanyName(true);
  }, []);

  const handleOnChangeRecipientEmails = useCallback((e) => {
    setRecipientEmails(e.target.value);
    setIsValidRecipientEmails(true);
  }, []);

  const getRecipientEmails = useCallback(() => {
    return recipientEmails.trim().split("\n");
  }, [recipientEmails]);

  const handleOnChangeIsTest = useCallback((e) => {
    setIsTest(e.target.checked);
  }, []);

  const isValidData = useCallback(() => {
    let isValid = true;
    if (companyName.trim().length > 0) {
      setIsValidCompanyName(true);
    } else {
      setIsValidCompanyName(false);
      isValid = false;
    }

    if (recipientEmails.trim().length > 0) {
      const emails = getRecipientEmails();
      for (let i = 0; i < emails.length; i++) {
        if (!validateEmail(emails[i])) {
          setIsValidRecipientEmails(false);
          isValid = false;
          break;
        }
      }
    } else {
      setIsValidRecipientEmails(false);
      isValid = false;
    }

    return isValid;
  }, [companyName, recipientEmails, getRecipientEmails]);

  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();
      if (isValidData()) {
        const emails = getRecipientEmails();
        setIsLoading(true);
        try {
          await companyService.updateCompany(companyId, companyName, emails);
          toast.success("Company updated successfully");
          handleOnSave({
            companyName,
            emails,
            isTest,
          });
        } catch (error) {
          toast.error(error.Message || "Error updating Company");
        }
        setIsLoading(false);
      }
    },
    [
      companyId,
      companyName,
      isTest,
      isValidData,
      handleOnSave,
      getRecipientEmails,
    ],
  );

  const handleClose = () => {
    handleOnCancel();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Edit company</DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>

        <FormControl fullWidth>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 2,
              gap: 2,
            }}
          >
            {isLoading && <LinearProgress />}
            <TextField
              fullWidth
              id="company-name"
              label="Company Name"
              name="company-name"
              type="text"
              required
              value={companyName}
              error={!isValidCompanyName}
              helperText={!isValidCompanyName ? "Enter a company name" : ""}
              onChange={handleOnChangeCompanyName}
              disabled={isLoading}
            />
            <TextField
              fullWidth
              id="recipient-emails"
              label="Recipient Emails"
              name="recipient-emails"
              type="text"
              required
              multiline
              value={recipientEmails}
              error={!isValidRecipientEmails}
              helperText={
                !isValidRecipientEmails
                  ? "Enter valid recipient emails, separated by line breaks"
                  : ""
              }
              onChange={handleOnChangeRecipientEmails}
              disabled={isLoading}
            />
            <FormControlLabel
              required
              control={<Checkbox />}
              label="is Test?"
              value={isTest}
              onChange={handleOnChangeIsTest}
              disabled
            />
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={isLoading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCompany;
