import { createContext, useState } from 'react';
import { load, clean, save, convertFromAPIResponse } from '../helpers/authStorage';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(() => load());

    const login = (responseAuth) => {
        const auth = convertFromAPIResponse(responseAuth);
        save(auth);
        setAuth(auth);
    };

    const logout = () => {
        clean();
        setAuth(null);
    };

    return <AuthContext.Provider value={{ auth, login, logout }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
