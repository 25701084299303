import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  LinearProgress,
  TextField,
} from "@mui/material";
import { toast } from "sonner";
import { productService } from "../api/productService";
import NumericFormatCustom from "../helpers/numericFormatCustom";

const EditProduct = ({
  companyId,
  supplierId,
  productId,
  isOpen,
  handleOnSave,
  handleOnCancel,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [techName, setTechName] = useState("");
  const [isValidTechName, setIsValidTechName] = useState(true);

  const [purchasePrice, setPurchasePrice] = useState(null);
  const [isValidPurchasePrice, setIsValidPurchasePrice] = useState(true);

  const [salesPrice, setSalesPrice] = useState(null);
  const [isValidSalesPrice, setIsValidSalesPrice] = useState(true);

  useEffect(() => {
    const loadProduct = async () => {
      setIsLoading(true);
      try {
        const product = await productService.getProduct(
          companyId,
          supplierId,
          productId,
        );
        setTechName(product.tech_name);
        setPurchasePrice(product.purchase_price);
        setSalesPrice(product.sales_price);
      } catch (error) {
        toast.error(error.Message || "Error getting Product");
      }
      setIsLoading(false);
    };
    loadProduct();
  }, [companyId, supplierId, productId]);

  const handleOnChangeTechName = useCallback((e) => {
    setTechName(e.target.value);
    setIsValidTechName(true);
  }, []);

  const handleOnChangePurchasePrice = useCallback((e) => {
    setPurchasePrice(e.target.value);
    setIsValidPurchasePrice(true);
  }, []);

  const handleOnChangeSalesPrice = useCallback((e) => {
    setSalesPrice(e.target.value);
    setIsValidSalesPrice(true);
  }, []);

  const isValidData = useCallback(() => {
    let isValid = true;

    if (techName.trim().length > 0) {
      setIsValidTechName(true);
    } else {
      setIsValidTechName(false);
      isValid = false;
    }

    if (purchasePrice > 0) {
      setIsValidPurchasePrice(true);
    } else {
      setIsValidPurchasePrice(false);
      isValid = false;
    }

    if (salesPrice > 0) {
      setIsValidSalesPrice(true);
    } else {
      setIsValidSalesPrice(false);
      isValid = false;
    }

    if (purchasePrice > salesPrice) {
      setIsValidSalesPrice(false);
      setIsValidPurchasePrice(false);
      isValid = false;
    }

    return isValid;
  }, [techName, purchasePrice, salesPrice]);

  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();
      if (isValidData()) {
        setIsLoading(true);
        try {
          await productService.updateProduct(
            companyId,
            supplierId,
            productId,
            techName,
            parseFloat(purchasePrice),
            parseFloat(salesPrice),
          );
          toast.success("Product updated successfully");
          handleOnSave({
            companyId,
            supplierId,
            productId,
            techName,
            purchasePrice,
            salesPrice,
          });
        } catch (error) {
          toast.error(error.Message || "Error updating Product");
        }
        setIsLoading(false);
      }
    },
    [
      companyId,
      supplierId,
      productId,
      techName,
      purchasePrice,
      salesPrice,
      isValidData,
      handleOnSave,
    ],
  );

  const handleClose = () => {
    handleOnCancel();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Edit product</DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>

        <FormControl fullWidth>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 2,
              gap: 2,
            }}
          >
            {isLoading && <LinearProgress />}
            <TextField
              fullWidth
              id="tech-name"
              label="Tech Name"
              name="tech-name"
              type="text"
              required
              value={techName}
              error={!isValidTechName}
              helperText={!isValidTechName ? "Enter a valid Tech Name" : ""}
              onChange={handleOnChangeTechName}
              disabled={isLoading}
            />
            <TextField
              fullWidth
              id="purchase-price"
              label="Purchase Price"
              name="purchase-price"
              type="text"
              required
              value={purchasePrice}
              error={!isValidPurchasePrice}
              helperText={
                !isValidPurchasePrice ? "Enter a valid Purchase Price" : ""
              }
              onChange={handleOnChangePurchasePrice}
              disabled={isLoading}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
            <TextField
              fullWidth
              id="sales-price"
              label="Sales Price"
              name="sales-price"
              type="text"
              required
              value={salesPrice}
              error={!isValidSalesPrice}
              helperText={!isValidSalesPrice ? "Enter a valid Sales Price" : ""}
              onChange={handleOnChangeSalesPrice}
              disabled={isLoading}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={isLoading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProduct;
