import React from 'react';

import '@fontsource/quicksand';
import { createTheme, responsiveFontSizes } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

const LinkBehavior = React.forwardRef((props, ref) => {
    const { href, ...other } = props;
    return <RouterLink ref={ref} to={href} {...other} />;
});

export const theme = responsiveFontSizes(
    createTheme({
        components: {
            MuiLink: {
                defaultProps: {
                    component: LinkBehavior,
                },
            },
            MuiButtonBase: {
                defaultProps: {
                    LinkComponent: LinkBehavior,
                },
            },
        },
        typography: {
            fontFamily: ['quicksand'].join(','),
            fontSize: 16,
            button: {
                fontSize: '1.2rem',
                fontWeight: 'bold',
            },
            link: {
                fontSize: '1.2rem',
                fontWeight: 'bold',
                color: '#00A7E1',
            },
            h1: {
                fontWeight: 'bold',
            },
            h2: {
                fontWeight: 'bold',
            },
            h3: {
                fontWeight: 'bold',
            },
            h4: {
                fontWeight: 'bold',
            },
            h5: {
                fontWeight: 'bold',
            },
            h6: {
                fontWeight: 'bold',
            },
        },
        palette: {
        },
    }),
);
