import Layout from "./Layout";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  Divider,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "sonner";
import moment from "moment";
import { payrollService } from "../api/payrollService";
import { DataGrid } from "@mui/x-data-grid";
import { invoiceService } from "../api/invoiceService";
import convertInvoiceStatus from "../helpers/convertInvoiceStatus";
import { getClassNameBasedOnInvoiceStatus } from "../helpers/getClassNameBasedOnInvoiceStatus";

const InvoiceDetails = () => {
  const { companyId, invoiceId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [payrolls, setPayrolls] = useState([]);

  const loadInvoice = useCallback(async () => {
    setIsLoading(true);
    try {
      const invoice = await invoiceService.getInvoice(companyId, invoiceId);
      setInvoiceData(invoice);
    } catch (error) {
      toast.error(error.Message || "Error getting invoice");
    }
    setIsLoading(false);
  }, [companyId, invoiceId]);

  const loadPayrolls = useCallback(async () => {
    setIsLoading(true);
    try {
      const payrolls = await payrollService.getPayrollsByInvoice(
        companyId,
        invoiceId,
      );
      setPayrolls(payrolls);
    } catch (error) {
      toast.error(error.Message || "Error getting payrolls");
    }
    setIsLoading(false);
  }, [invoiceId, companyId]);

  useEffect(() => {
    loadInvoice();
    loadPayrolls();
  }, [loadInvoice, loadPayrolls]);

  const markInvoiceAsSent = useCallback(async () => {
    setIsLoading(true);
    try {
      await invoiceService.markInvoiceAsSent(companyId, invoiceId);
      toast.success("Invoice marked as sent");
      loadInvoice();
    } catch (error) {
      toast.error(error.Message || "Error marking invoice as sent");
    }
    setIsLoading(false);
  }, [companyId, invoiceId, loadInvoice]);

  const markInvoiceAsPending = useCallback(async () => {
    setIsLoading(true);
    try {
      await invoiceService.markInvoiceAsPending(companyId, invoiceId);
      toast.success("Invoice marked as pending");
      loadInvoice();
    } catch (error) {
      toast.error(error.Message || "Error marking invoice as pending");
    }
    setIsLoading(false);
  }, [companyId, invoiceId, loadInvoice]);

  const markInvoiceAsNoCreated = useCallback(async () => {
    setIsLoading(true);
    try {
      await invoiceService.markInvoiceAsNoCreated(companyId, invoiceId);
      toast.success("Invoice marked as no created");
      loadInvoice();
    } catch (error) {
      toast.error(error.Message || "Error marking invoice as no created");
    }
    setIsLoading(false);
  }, [companyId, invoiceId, loadInvoice]);

  const payrollSupplierColumns = [
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      headerClassName: "column-header",
      valueFormatter: (params) =>
        moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      field: "billId",
      flex: 1,
      headerName: "QBO Bill Id",
      headerClassName: "column-header",
      cellClassName: "titled",
      renderCell: (params) => {
        return (
          <Chip
            variant="outlined"
            color="primary"
            key={params.row.id}
            label={params.value}
          />
        );
      },
    },
    {
      field: "techName",
      flex: 1,
      headerName: "Tech Name",
      headerClassName: "column-header",
      cellClassName: "titled",
    },
    {
      field: "hours",
      flex: 0.3,
      headerName: "Hours",
      headerClassName: "column-header",
    },
    {
      field: "value",
      flex: 0.3,
      headerName: "Rate",
      headerClassName: "column-header",
      valueFormatter: (params) => {
        if (params.value) {
          return `$ ${params.value.toLocaleString()}`;
        }
        return "";
      },
    },
    {
      field: "expeditionDate",
      flex: 0.5,
      headerName: "Expedition Date",
      headerClassName: "column-header",
    },
    {
      field: "invoiceNumber",
      flex: 0.5,
      headerName: "Invoice Number",
      headerClassName: "column-header",
    },
    {
      field: "subCompanyName",
      flex: 0.5,
      headerName: "Supplier",
      headerClassName: "column-header",
    },
  ];

  const payrollCustomerColumns = [
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      headerClassName: "column-header",
      valueFormatter: (params) =>
        moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      field: "techName",
      flex: 1,
      headerName: "Tech Name",
      headerClassName: "column-header",
      cellClassName: "titled",
    },
    {
      field: "hours",
      flex: 0.3,
      headerName: "Hours",
      headerClassName: "column-header",
    },
    {
      field: "value",
      flex: 0.3,
      headerName: "Rate",
      headerClassName: "column-header",
      valueFormatter: (params) => {
        if (params.value) {
          return `$ ${params.value.toLocaleString()}`;
        }
        return "";
      },
    },
  ];

  const getPayrollsData = (subCompanyType) => {
    const filteredPayrolls = payrolls.filter((payroll) => {
      return payroll.sub_company_type === subCompanyType;
    });

    return filteredPayrolls.map((payroll) => {
      return {
        id: payroll.id,
        createdAt: new Date(payroll.created_at + "Z"),
        techName: payroll.tech_name,
        hours: payroll.hours,
        startDate: payroll.pay_period_start_date,
        endDate: payroll.pay_period_end_date,
        status: payroll.status,
        syncDate: payroll.sync_date,
        value: payroll.value,
        expeditionDate: payroll.expedition_date,
        invoiceNumber: payroll.invoice_number,
        subCompanyType: payroll.sub_company_type,
        subCompanyName: payroll.sub_company_name,
        billId: payroll.quickbooks_bill_id,
      };
    });
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3">Invoice Details</Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            navigate(-1, { replace: true });
          }}
        >
          Back
        </Button>
      </Box>
      <Divider sx={{ mb: 2, mt: 2 }} />
      {isLoading && <LinearProgress />}
      {!isLoading && invoiceData && (
        <Box>
          <TableContainer>
            <Table aria-label="simple table">
              <colgroup>
                <col width="300px" />
              </colgroup>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Created At</TableCell>
                  <TableCell>
                    {moment(invoiceData.created_at + "Z").format(
                      "MMMM Do YYYY, h:mm:ss a",
                    )}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Start Date</TableCell>
                  <TableCell>{invoiceData.pay_period_start_date}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>End Date</TableCell>
                  <TableCell>{invoiceData.pay_period_end_date}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Quickbooks Invoice Id</TableCell>
                  <TableCell>
                    {invoiceData.quickbooks_id && (
                      <Chip
                        variant="outlined"
                        color="primary"
                        key={invoiceData.id}
                        label={invoiceData.quickbooks_id}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Quickbooks Bill Ids</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                        gap: 1,
                      }}
                    >
                      {invoiceData.quickbooks_bill_ids.map((id) => {
                        return (
                          <Chip
                            variant="outlined"
                            color="primary"
                            key={invoiceData.id + id}
                            label={id}
                          />
                        );
                      })}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Status</TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {convertInvoiceStatus(invoiceData.status)}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Sync Date</TableCell>
                  <TableCell>{invoiceData.sync_date}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {invoiceData.status === "pending" && (
            <Box>
              <Divider sx={{ mb: 2, mt: 2 }} />
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={markInvoiceAsSent}
              >
                Mark Invoice as Sent
              </Button>
            </Box>
          )}
          {invoiceData.status === "no_created" && (
            <Box>
              <Divider sx={{ mb: 2, mt: 2 }} />
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={markInvoiceAsSent}
              >
                Mark Invoice as Sent
              </Button>
            </Box>
          )}
          {invoiceData.status === "sent" && (
            <Box>
              <Divider sx={{ mb: 2, mt: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={markInvoiceAsPending}
                >
                  Mark Invoice as Pending
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={markInvoiceAsNoCreated}
                >
                  Mark Invoice as No Created
                </Button>
              </Box>
            </Box>
          )}
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h4">Customer Payrolls</Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <DataGrid
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-row:hover": {},
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                  {
                    outline: "none !important",
                  },
              }}
              rows={getPayrollsData("customer")}
              columns={payrollCustomerColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableRowSelectionOnClick
            />
          </Box>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h4">Supplier Payrolls</Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <DataGrid
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-row:hover": {},
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                  {
                    outline: "none !important",
                  },
              }}
              rows={getPayrollsData("supplier")}
              columns={payrollSupplierColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableRowSelectionOnClick
            />
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default InvoiceDetails;
