import { apiPrivateClient } from "./apiService";
import { handleApiCall } from "./handleApiCall";

export const companyService = {
  async getCompanies() {
    return handleApiCall(() => apiPrivateClient.get("/companies"));
  },

  async createCompany(name, recipientEmails, isTest) {
    return handleApiCall(() =>
      apiPrivateClient.post("/companies", {
        name: name,
        recipient_emails: recipientEmails,
        is_test: isTest,
      }),
    );
  },

  async getCompany(companyId) {
    return handleApiCall(() =>
      apiPrivateClient.get(`/companies/${companyId}/`),
    );
  },

  async updateCompany(companyId, name, recipientEmails) {
    return handleApiCall(() =>
      apiPrivateClient.put(`/companies/${companyId}`, {
        name: name,
        recipient_emails: recipientEmails,
      }),
    );
  },

  async getOauth2InitUrl(companyId) {
    return handleApiCall(() =>
      apiPrivateClient.post(`/companies/${companyId}/oauth2/init`),
    );
  },

  async checkQuickbooksConnection(companyId) {
    return handleApiCall(() =>
      apiPrivateClient.get(`/companies/${companyId}/quickbooks/info`),
    );
  },
};
