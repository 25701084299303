import React, { useCallback, useEffect, useState } from "react";

import Layout from "./Layout";
import useAuth from "../hooks/useAuth";
import { toast } from "sonner";
import { companyService } from "../api/companyService";
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import LaunchIcon from "@mui/icons-material/Launch";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import InfoIcon from "@mui/icons-material/Info";
import AddCompany from "./AddCompany";
import EditCompany from "./EditCompany";
import AccountMenu from "./AccountMenu";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import ClearIcon from "@mui/icons-material/Clear";
import { emailService } from "../api/emailService";

const Home = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isAddCompanyOpen, setIsAddCompanyOpen] = useState(false);
  const [isEditCompanyOpen, setIsEditCompanyOpen] = useState(false);
  const [companyIdToEdit, setCompanyIdToEdit] = useState(null);

  const [companies, setCompanies] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);

  const loadCompanies = useCallback(async () => {
    setIsLoading(true);
    try {
      const companies = await companyService.getCompanies();
      setCompanies(companies);
    } catch (error) {
      toast.error(error.Message || "Error getting companies");
    }
    setIsLoading(false);
  }, []);

  const loadInvalidEmails = useCallback(async () => {
    setIsLoading(true);
    try {
      const invalidEmails = await emailService.getInvalidEmails();
      setInvalidEmails(invalidEmails);
    } catch (error) {
      toast.error(error.Message || "Error getting invalid emails");
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadCompanies();
    loadInvalidEmails();
  }, [loadCompanies, loadInvalidEmails]);

  const getCompaniesData = () => {
    return companies.map((company) => {
      return {
        id: company.id,
        createdAt: new Date(company.created_at + "Z"),
        name: company.name,
        isTest: company.is_test ? "Yes" : "No",
        hasCredentials: company.has_credentials,
        recipientEmails: company.recipient_emails,
      };
    });
  };

  const getInvalidEmailsData = () => {
    return invalidEmails.map((email) => {
      return {
        id: email.id,
        receivedAt: new Date(email.receive_datetime),
        sender: email.sender,
        subject: email.subject,
        errorMessage: email.error_message,
      };
    });
  };

  const handleOnDownloadAttachments = useCallback(async (emailId) => {
    setIsLoading(true);
    try {
      const urls = await emailService.getDownloadAttachmentsUrls(emailId);
      urls.forEach((url) => {
        window.open(url);
      });
    } catch (error) {
      toast.error(error.Message || "Error downloading attachments");
    }
    setIsLoading(false);
  }, []);

  const handleOnClearInvalidEmail = useCallback(
    async (emailId) => {
      setIsLoading(true);
      try {
        await emailService.clearInvalidEmail(emailId);
        toast.success("Invalid email removed successfully");
        loadInvalidEmails();
      } catch (error) {
        toast.error(error.Message || "Error removing invalid email");
      }
      setIsLoading(false);
    },
    [loadInvalidEmails],
  );

  const renderActions = (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <IconButton
          variant="outlined"
          color="primary"
          size="small"
          aria-label="open"
          onClick={() => {
            navigate(`/companies/${params.row.id}`);
          }}
          title="Open"
          disabled={!params.row.hasCredentials}
        >
          <LaunchIcon />
        </IconButton>
        <IconButton
          variant="outlined"
          color="primary"
          size="small"
          aria-label="edit"
          onClick={() => {
            setCompanyIdToEdit(params.row.id);
            setIsEditCompanyOpen(true);
          }}
          title="Edit"
        >
          <ModeEditIcon />
        </IconButton>
        <IconButton
          variant="outlined"
          color="primary"
          size="small"
          aria-label="qb_info"
          onClick={() => {
            handleOnCheckQuickbooksConnection(params.row.id);
          }}
          title="QBO Info"
          disabled={!params.row.hasCredentials}
        >
          <InfoIcon />
        </IconButton>
      </Box>
    );
  };

  const renderHasCredentials = (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        {params.row.hasCredentials ? (
          <Typography>Yes</Typography>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              handleOnConnectToQuickbooks(params.row.id);
            }}
          >
            Connect
          </Button>
        )}
      </Box>
    );
  };

  const renderRecipientEmails = (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {params.row.recipientEmails.map((email) => (
          <Chip
            key={params.row.id + "-" + email}
            label={email}
            variant="outlined"
            color="primary"
          />
        ))}
      </Box>
    );
  };

  const renderEmailActions = (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <IconButton
          variant="outlined"
          color="primary"
          size="small"
          aria-label="open"
          onClick={() => {
            handleOnDownloadAttachments(params.row.id);
          }}
          title="Download Attachments"
        >
          <DownloadIcon />
        </IconButton>
        <IconButton
          variant="outlined"
          color="primary"
          size="small"
          aria-label="clear"
          onClick={() => {
            handleOnClearInvalidEmail(params.row.id);
          }}
          title="Remove"
        >
          <ClearIcon />
        </IconButton>
      </Box>
    );
  };

  const getCompanyColumns = () => {
    let columns = [
      {
        field: "createdAt",
        headerName: "Created At",
        flex: 0.5,
        headerClassName: "column-header",
        valueFormatter: (params) =>
          moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
      },
      {
        field: "name",
        flex: 1,
        headerName: "Name",
        headerClassName: "column-header",
      },
      {
        field: "hasCredentials",
        flex: 0.5,
        headerName: "Has Credentials?",
        headerClassName: "column-header",
        renderCell: renderHasCredentials,
      },
      {
        field: "recipientEmails",
        flex: 1,
        headerName: "Recipient Emails",
        sortable: false,
        headerClassName: "column-header",
        renderCell: renderRecipientEmails,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: renderActions,
        flex: 0.5,
        headerClassName: "column-header",
      },
    ];

    if (auth.canTest) {
      return [
        ...columns.slice(0, 2),
        {
          field: "isTest",
          flex: 0.5,
          headerName: "Is Test?",
          headerClassName: "column-header",
        },
        ...columns.slice(2),
      ];
    } else {
      return columns;
    }
  };

  const invalidEmailColumns = [
    {
      field: "receivedAt",
      headerName: "Received At",
      flex: 1,
      headerClassName: "column-header",
      valueFormatter: (params) =>
        moment(params?.value).format("MMMM Do YYYY, h:mm:ss a"),
    },
    {
      field: "sender",
      flex: 1,
      headerName: "Sender",
      headerClassName: "column-header",
    },
    {
      field: "subject",
      flex: 1,
      headerName: "Subject",
      headerClassName: "column-header",
    },
    {
      field: "errorMessage",
      flex: 1,
      headerName: "Error Message",
      headerClassName: "column-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 0.5,
      renderCell: renderEmailActions,
      headerClassName: "column-header",
    },
  ];

  const handleOnConnectToQuickbooks = async (companyId) => {
    setIsLoading(true);
    try {
      const response = await companyService.getOauth2InitUrl(companyId);
      const url = response.url;
      window.open(url, "_self");
    } catch (error) {
      toast.error(error.Message || "Error connecting to Quickbooks");
    }
  };

  const handleOnCheckQuickbooksConnection = async (companyId) => {
    setIsLoading(true);
    try {
      await companyService.checkQuickbooksConnection(companyId);
      toast.success("Quickbooks connected successfully");
    } catch (error) {
      toast.error(error.Message || "Error connecting to Quickbooks");
    }
    setIsLoading(false);
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 2,
          }}
        >
          Hello {auth.firstName}
        </Typography>
        <AccountMenu />
      </Box>
      <Divider sx={{ mb: 2, mt: 2 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h4">Companies</Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            setIsAddCompanyOpen(true);
          }}
        >
          Add New
        </Button>
      </Box>
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <Box>
          <Box sx={{ width: "100%" }}>
            <DataGrid
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-row:hover": {},
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                  {
                    outline: "none !important",
                  },
              }}
              getRowHeight={() => "auto"}
              rows={getCompaniesData()}
              columns={getCompanyColumns()}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableRowSelectionOnClick
            />
          </Box>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h4">Invalid Emails</Typography>
          </Box>
          <DataGrid
            sx={{
              boxShadow: 2,
              "& .MuiDataGrid-row:hover": {},
              "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                {
                  outline: "none !important",
                },
            }}
            getRowHeight={() => "auto"}
            rows={getInvalidEmailsData()}
            columns={invalidEmailColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick
          />
        </Box>
      )}
      {isAddCompanyOpen && (
        <AddCompany
          isOpen={isAddCompanyOpen}
          handleOnSave={(args) => {
            setIsAddCompanyOpen(false);
            loadCompanies();
            console.log(args);
          }}
          handleOnCancel={() => {
            setIsAddCompanyOpen(false);
          }}
        />
      )}
      {isEditCompanyOpen && (
        <EditCompany
          companyId={companyIdToEdit}
          isOpen={isEditCompanyOpen}
          handleOnSave={(args) => {
            setIsEditCompanyOpen(false);
            loadCompanies();
            console.log(args);
          }}
          handleOnCancel={() => {
            setIsEditCompanyOpen(false);
          }}
        />
      )}
    </Layout>
  );
};

export default Home;
