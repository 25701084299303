import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { toast } from "sonner";
import useAuth from "../hooks/useAuth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { userService } from "../api/userService";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const navigate = useNavigate();

  const handleOnChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
    setIsValidEmail(true);
  }, []);

  const handleOnChangePassword = useCallback((e) => {
    setPassword(e.target.value);
    setIsValidPassword(true);
  }, []);

  const validateEmail = useCallback((email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }, []);

  const isValidData = useCallback(() => {
    let isValid = true;
    if (validateEmail(email)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
      isValid = false;
    }

    if (password.length > 0) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(false);
      isValid = false;
    }

    return isValid;
  }, [email, password, validateEmail]);

  const handleOnSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (isValidData()) {
        setIsLoading(true);
        try {
          const responseAuth = await userService.auth(email, password);
          login(responseAuth);
          navigate("/");
        } catch (error) {
          toast.error("The email or password is incorrect");
        }
        setIsLoading(false);
      }
    },
    [email, password, isValidData, login, navigate],
  );

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh", textAlign: "center" }}
    >
      <Grid item>
        <Stack
          sx={{
            maxWidth: "600px",
            minWidth: { sm: "600px" },
            mx: "auto",
            display: "flex",
            flexDirection: "column",
            padding: "30px",
            border: "1px solid",
            borderRadius: "10px",
            boxShadow: 10,
            backgroundColor: "primary.contrastText",
          }}
        >
          <Box>
            <img src="/logo.png" alt="logo" style={{ maxWidth: "200px" }} />
          </Box>
          <Typography variant="h4">Monaral</Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              autoComplete="email"
              autoFocus
              disabled={isLoading}
              error={!isValidEmail}
              fullWidth
              helperText={!isValidEmail ? "Enter your email" : ""}
              id="email"
              label="Email"
              margin="normal"
              name="email"
              type="email"
              onChange={handleOnChangeEmail}
            />
            <TextField
              autoComplete="current-password"
              disabled={isLoading}
              error={!isValidPassword}
              fullWidth
              helperText={!isValidPassword ? "Enter your password" : ""}
              id="password"
              label="Password"
              margin="normal"
              name="password"
              onChange={handleOnChangePassword}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      disabled={isLoading}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!isLoading && (
              <Button
                disabled={isLoading}
                fullWidth
                onClick={handleOnSubmit}
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
              >
                Log In
              </Button>
            )}
            {isLoading && <LinearProgress sx={{ mt: 6, mb: 3 }} />}
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Login;
