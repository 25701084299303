import { apiPrivateClient } from "./apiService";
import { handleApiCall } from "./handleApiCall";

export const productService = {
  async getAllProducts(companyId) {
    return handleApiCall(() =>
      apiPrivateClient.get(`/companies/${companyId}/products`),
    );
  },

  async createProduct(
    companyId,
    subCompanyId,
    techName,
    purchasePrice,
    salesPrice,
  ) {
    return handleApiCall(() =>
      apiPrivateClient.post(
        `/companies/${companyId}/sub-companies/${subCompanyId}/products`,
        {
          tech_name: techName,
          purchase_price: purchasePrice,
          sales_price: salesPrice,
        },
      ),
    );
  },

  async getProduct(companyId, subCompanyId, productId) {
    return handleApiCall(() =>
      apiPrivateClient.get(
        `/companies/${companyId}/sub-companies/${subCompanyId}/products/${productId}`,
      ),
    );
  },

  async updateProduct(
    companyId,
    subCompanyId,
    productId,
    techName,
    purchasePrice,
    salesPrice,
  ) {
    return handleApiCall(() =>
      apiPrivateClient.put(
        `/companies/${companyId}/sub-companies/${subCompanyId}/products/${productId}`,
        {
          tech_name: techName,
          purchase_price: purchasePrice,
          sales_price: salesPrice,
        },
      ),
    );
  },
};
