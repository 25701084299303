import React from 'react';
import { Box } from '@mui/material';

const Layout = ({ children }) => {
    return (
        <Box
            sx={{
                p: 5,
                backgroundColor: 'primary.contrastText',
            }}
        >
            {children}
        </Box>
    );
};

export default Layout;
