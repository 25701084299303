import axios from 'axios';
import { load } from '../helpers/authStorage';

export const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const apiPrivateClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

apiPrivateClient.interceptors.request.use(
    async (config) => {
        const auth = load();
        const token = auth.token;

        if (token) {
            config.headers['Authorization'] = `Basic ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
