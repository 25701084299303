import { Box, Stack } from "@mui/material";
import Layout from "./components/Layout";
import React from "react";

const ErrorPage = () => {
  return (
    <Layout>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ textAlign: "center" }}
      >
        <Box sx={{ mt: 10 }}>
          <img
            src="/images/not-found.png"
            alt="not found"
            style={{ maxWidth: "200px" }}
          />
        </Box>
        <div>
          <h1>Oops!</h1>
          <h2>Sorry, an unexpected error has occurred.</h2>
          <h2>
            <i>Not found</i>
          </h2>
        </div>
      </Stack>
    </Layout>
  );
};

export default ErrorPage;
