import { apiPrivateClient } from "./apiService";
import { handleApiCall } from "./handleApiCall";

export const emailService = {
  async getAllEmails(companyId) {
    return handleApiCall(() =>
      apiPrivateClient.get(`/companies/${companyId}/emails`),
    );
  },

  async getDownloadAttachmentsUrls(emailId) {
    return handleApiCall(() =>
      apiPrivateClient.post(`/emails/${emailId}/attachments`),
    );
  },

  async getInvalidEmails() {
    return handleApiCall(() => apiPrivateClient.get(`/emails/invalid`));
  },

  async clearInvalidEmail(emailId) {
    return handleApiCall(() =>
      apiPrivateClient.delete(`/emails/${emailId}/invalid`),
    );
  },

  async getEmail(emailId) {
    return handleApiCall(() => apiPrivateClient.get(`/emails/${emailId}`));
  },
};
