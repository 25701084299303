const getClassNameBasedOnInvoiceStatus = (status) => {
  if (status === "Pending") {
    return "pending-invoice";
  } else if (status === "Sent") {
    return "sent-invoice";
  } else if (status === "No Created") {
    return "no-created-invoice";
  }
};

export { getClassNameBasedOnInvoiceStatus };
