import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  LinearProgress,
  TextField,
} from "@mui/material";
import { toast } from "sonner";
import { validateEmail } from "../helpers/stringHelper";
import { subCompanyService } from "../api/subCompanyService";

const EditSubCompany = ({
  companyId,
  subCompanyId,
  isOpen,
  handleOnSave,
  handleOnCancel,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [subCompanyName, setSubCompanyName] = useState("");
  const [isValidSubCompanyName, setIsValidSubCompanyName] = useState(true);

  const [emailFrom, setEmailFrom] = useState("");
  const [isValidEmailFrom, setIsValidEmailFrom] = useState(true);

  useEffect(() => {
    const loadSubCompany = async () => {
      setIsLoading(true);
      try {
        const subCompany = await subCompanyService.getSubCompany(
          companyId,
          subCompanyId,
        );
        setSubCompanyName(subCompany.name);
        setEmailFrom(subCompany.email_from);
      } catch (error) {
        toast.error(error.Message || "Error getting Sub Company");
      }
      setIsLoading(false);
    };
    loadSubCompany();
  }, [companyId, subCompanyId]);

  const handleOnChangeSubCompanyName = useCallback((e) => {
    setSubCompanyName(e.target.value);
    setIsValidSubCompanyName(true);
  }, []);

  const handleOnChangeEmailFrom = useCallback((e) => {
    setEmailFrom(e.target.value);
    setIsValidEmailFrom(true);
  }, []);

  const isValidData = useCallback(() => {
    let isValid = true;
    if (subCompanyName.trim().length > 0) {
      setIsValidSubCompanyName(true);
    } else {
      setIsValidSubCompanyName(false);
      isValid = false;
    }

    if (emailFrom.length > 0 && validateEmail(emailFrom)) {
      setIsValidEmailFrom(true);
    } else {
      setIsValidEmailFrom(false);
      isValid = false;
    }

    return isValid;
  }, [subCompanyName, emailFrom]);

  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();
      if (isValidData()) {
        setIsLoading(true);
        try {
          await subCompanyService.updateSubCompany(
            companyId,
            subCompanyId,
            subCompanyName,
            emailFrom,
          );
          toast.success("Sub Company updated successfully");
          handleOnSave({
            subCompanyName: subCompanyName,
            emailFrom: emailFrom,
          });
        } catch (error) {
          toast.error(error.Message || "Error updating Sub Company");
        }
        setIsLoading(false);
      }
    },
    [
      companyId,
      subCompanyId,
      subCompanyName,
      emailFrom,
      isValidData,
      handleOnSave,
    ],
  );

  const handleClose = () => {
    handleOnCancel();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Edit sub company</DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>

        <FormControl fullWidth>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 2,
              gap: 2,
            }}
          >
            {isLoading && <LinearProgress />}
            <TextField
              fullWidth
              id="sub-company-name"
              label="Sub Company Name"
              name="sub-company-name"
              type="text"
              required
              value={subCompanyName}
              error={!isValidSubCompanyName}
              helperText={
                !isValidSubCompanyName ? "Enter a sub company name" : ""
              }
              onChange={handleOnChangeSubCompanyName}
              disabled={isLoading}
            />
            <TextField
              fullWidth
              id="email-from"
              label="Email From"
              name="email-from"
              type="text"
              required
              value={emailFrom}
              error={!isValidEmailFrom}
              helperText={!isValidEmailFrom ? "Enter a valid email" : ""}
              onChange={handleOnChangeEmailFrom}
              disabled={isLoading}
            />
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={isLoading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSubCompany;
